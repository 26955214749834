export default [
  {
    path: '/overview',
    name: 'dashboard',
    component: () => import('@/views/for_student/my_courses/index'),
    meta: {
      requiresAuth: true,
      permission: 'admin_system'
    },
  },
]
